import { domain } from "../../domain";

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const FETCH_ORDER_HISTORY_REQUEST = 'FETCH_ORDER_HISTORY_REQUEST';
export const FETCH_ORDER_HISTORY_SUCCESS = 'FETCH_ORDER_HISTORY_SUCCESS';
export const FETCH_ORDER_HISTORY_FAILURE = 'FETCH_ORDER_HISTORY_FAILURE';

export const fetchOrders = (token) => {
  return async dispatch =>{
    //Start Loading
    dispatch(
      {
        type : FETCH_ORDER_REQUEST,
      });

    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
    
      fetch(`${domain}/api/current-order/`, requestOptions)
      .then(response => response.text())
        .then(result => {
          result = JSON.parse(result);
          dispatch(
            {
              type : FETCH_ORDER_SUCCESS,
              payload:result,
            }
          );
        })
        .catch(error =>{
          dispatch(
            {
              type : FETCH_ORDER_FAILURE,
              payload:error,
            }
          );
        })
    }
    catch{

    }
  }
}

export const fetchOrderHistory = (token) =>{
    return async dispatch =>{
    //Start Loading
    dispatch(
      {
        type : FETCH_ORDER_HISTORY_REQUEST,
      });

    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
    
      fetch(`${domain}/api/history-order/`, requestOptions)
      .then(response => response.text())
        .then(result => {
          result = JSON.parse(result);
          dispatch(
            {
              type : FETCH_ORDER_HISTORY_SUCCESS,
              payload:result,
            }
          );
        })
        .catch(error =>{
          dispatch(
            {
              type : FETCH_ORDER_HISTORY_FAILURE,
              payload:error,
            }
          );
        })
    }
    catch{

    }
  }
}