import React,{useEffect,useState} from 'react'
import { NavBar } from '../../components/NavBar';
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import {getAddress,removeAddress,addAdress} from '../../statemanagement/actions/adress';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/Loading';
import semtler from "../../assets/ilce-semt.json";
import { Cart } from '../../components/Cart';
import { Footer } from '../../components/Footer';
import { getIds, getTotal } from '../../statemanagement/actions/cart';
import { domain } from '../../domain';

const Checkout = () => {
  let navigate = useNavigate();
  const {cart_ids,gel_al,total,use_points} = useSelector(state => state.CartReducer);
  const {user_info,loaded} = useSelector(state => state.AccountReducer);

  //Address Part
  const {addresses,loading} = useSelector(state => state.AdressReducer);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow2(false);
  const handleShow3 = () => setShow2(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [success,setSuccess] = useState(true);
  // Address Modal vars
  const [show, setShow] = useState(false);
  const [type,setType] = useState("");
  const [Address,setAddress] = useState("");
  const [il,setil] = useState("İzmir");
  const [ilce,setilce] = useState("");
  const [semt,setsemt] = useState("");
  const [AdresTarifi,setAdresTarifi] = useState("");
  const [telefon,settelefon] = useState("");
  const [error,setError] = useState(false);
  const iller = ["Bayraklı","Bornova","Buca","Karbağlar","Karşıyaka","Konak"];
  const [phone,setPhone] = useState("");
  const [description,setDescription] = useState("");
  const [message,setMessage] = useState("");
  const [error2,setError2] = useState(false);
  //Order API's body variables
  const [SelectedAddress,setSelectedAddress] = useState("");

  useEffect(() => {
      
      if(localStorage.getItem('access-token') === null){
        navigate('/home');
      }
      else{
          dispatch(getAddress(localStorage.getItem('access-token')));
          loaded ? setPhone(user_info[0][2]):setPhone('');
      }
      
  },[loaded]);

  function makeOrder(){
    if (!gel_al && SelectedAddress.length === 0){
        setMessage("Lütfen bir adres seçin ve sipariş vermeye devam edin!");
        handleShow2();
        return;
    }
    if(use_points && user_info[0][1] > total){
        setMessage("Puanlarınız sepetinizin toplamından fazla, lütfen sepetinize daha fazla ürün ekleyin!");
        handleShow2();
        return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${window.localStorage.getItem('access-token')}`);
    myHeaders.append("Content-Type", "application/json");
    dispatch(getIds());
    dispatch(getTotal());
    var requestTotal = total;
    if(use_points){
        requestTotal = total - user_info[0][1];
    }
    if(user_info[0][6]){
        requestTotal = requestTotal - (total *0.2);
    }
    var raw= ""
    if(gel_al){
        raw = JSON.stringify({
            "user": user_info[0][5],
            "products": cart_ids,
            "total": requestTotal,
            "use_points": use_points,
            "gel_al": gel_al,
            "details": description,
            "number": phone,
        });
    }
    else{
        raw = JSON.stringify({
            "user": user_info[0][5],
            "products": cart_ids,
            "total": requestTotal,
            "use_points": use_points,
            "gel_al": gel_al,
            "details": description,
            "number": phone,
            "Address": SelectedAddress.id,
        });
        
    }

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    handleShow3();
    fetch(`${domain}/api/order/`, requestOptions)
    .then(response => response.text())
    .then(result => {
        const res = JSON.parse(result);
        if(res.error != undefined){
            setMessage(res.error);
            handleShow2();
            return;
        }
        if(result.code === "token_not_valid"){
            localStorage.clear();
            window.location.reload();
        }
        else{
            handleClose3();
            setSuccess(true);
            localStorage.removeItem('cart');
            setMessage("Siparişiniz başarıyla oluşturuldu, lütfen Siparişler sayfasından durumunu kontrol edin.");
            handleShow2();
        }
    })
    .catch(error =>{
        setMessage(error.error);
        handleShow2();
    });
  }

  return (
    <>
      <NavBar/>

      <div className='menu-row px-3 p-5 row m-auto'>
            {/* Addres and cart column */}
            <div className='col-1'></div>
            <div className='col-xl-6 col-md-5 me-5 col-sm-12'>
                {/* Address Tab */}
                <div className={`mobile-col col-sm-12 mb-5 ${gel_al?'disabled-div':''}` }>
                    <div id='menu' className='add-col' >
                        <div className='ps-3 pt-3 row'>
                            <h1 className=' col-md-8 col-6 heading'>
                                Adreslerim
                            </h1>
                            <button onClick={handleShow} className='btn btn-outline-dark w-10 col-lg-2 col-md-3 col-5'>
                                Yeni Adres
                            </button>
                        </div>
                        <>
                        {
                            loading ?
                            <Loading/>
                            :<>
                                {addresses.map((e)=>
                                    <>            
                                    <div className='row ps-3 pt-2 border-bottom'>
                                        <div className='col-10'>
                                        <div className='row'>
                                            <div className='col-1 d-flex align-items-center'>
                                                <input type="radio" id={e.id} onChange={()=>setSelectedAddress(e)} value={e}  checked={SelectedAddress.id === e.id} />
                                            </div>
                                            <div className='col-10'>
                                                <h4 className='col-12'>
                                                    {e.type}
                                                </h4>
                                                <p className='text-secondary col-12'>
                                                    {e.Address} <br/>
                                                    {e.semt}{", "} {e.ilce} {", "} {e.il} <br/>
                                                    {e.AdresTarifi}
                                                </p>
                                            </div>
                                        </div>
                    
                                        </div>
                                    </div>
                                    </>
                                )
                                }
                            </>
                        }
                        </>   
                    </div>
                </div>
                {/* ONAY TAB */}
                <div className='mobile-col col-sm-12'>
                    <div className=" d-block p-2 mb-3">
                        <h5 className="fs-3 mt-2">Sipariş Onayı</h5>
                            <div className="card date-bg d-block p-2">
                                <h6 className="delivery">Teslimat Tarihi</h6>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="asap" name="FutureOrder" value="false" checked="checked" className="custom-control-input" required=""/>
                                        <label className="custom-control-label" for="asap">Hemen</label>
                                    </div>
                            </div>
                            <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="phone">Cep Telefonu:</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">+90</span>
                                    </div>
                                    <input type="number"value={phone}     onInput={(e) => {if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10)}}
                                        name='phone' onChange={(e)=> setPhone(e.target.value)} className="form-control"  aria-label="Phone" aria-describedby="basic-addon1"/>
                                </div>
                            </div>
                        <div className="payment-system col-md-6">
                            <label className="delivery">Sipariş için notunuz</label>
                            <textarea name="note" className="form-control font-12" rows="5" value={description} onChange={(e)=>setDescription(e.target.value)}></textarea>
                        </div>
                            </div>
                    </div>
                </div>
            </div>
            {/* Cart column */}
            <div className='col-md-5 col-xl-4 col-sm-12 mt-3'>
                <Cart Checkoutfun={makeOrder} Checkout={true}/>
            </div>
            {/* Address Modal */}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              >
                  <Modal.Header>
                      <Modal.Title>Yeni Adres</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {/* Type */}
                  <div >
                      <label for="disabledSelect" className="form-label">Adres Tipi: *</label>
                      <select id="disabledSelect" onChange={(e)=> setType(e.target.value)} className="form-select">
                          <option selected disabled hidden>Lütfen seçiniz...</option>
                          <option value={"Ev"}>Ev</option>
                          <option value={"İş"} >İş</option>
                          <option value={"Kampüs"} >Kampüs</option>
                      </select>
                      {error && type.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}

                  </div>
                  <div>
                      <label for="message-text" className="col-form-label">Adres: *</label>
                      <input value={Address}  onChange={(e)=> setAddress(e.target.value)}className="form-control" type="text" id="message-text"/>
                      {error && Address.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}
                  </div>
                  <div>
                      <label for="disabledSelect" className="form-label">İl: *</label>
                      <select id="disabledSelect" value={il} disabled  placeholder='İzmir' onChange={(e)=> setil(e.target.value)} className="form-select">
                          <option selected disabled hidden value={'İzmir'}>İzmir</option>
                      </select>
                  </div>
                  <div>
                      <label for="disabledSelect" className="form-label">İlçe: *</label>
                      <select id="disabledSelect" onChange={(e)=> setilce(e.target.value)} className="form-select">  
                          <option selected disabled hidden>Lütfen seçiniz...</option>
                          {iller.map((option, index) => {
                              return <option key={index} >
                                  {option}
                              </option>
                          })}
                      </select>
                      {error && ilce.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}

                  </div>
                  <div >
                      <label for="disabledSelect" className="form-label">Semt: *</label>
                      <select id="disabledSelect" onChange={(e)=> setsemt(e.target.value)} className="form-select">  
                          <option selected disabled hidden>Lütfen seçiniz...</option>
                          {semtler[ilce].map((option, index) => {
                              return <option key={index} >
                                  {option}
                              </option>
                          })}
                      </select>
                      {error && semt.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}
                  </div>
                  <div>
                      <label for="message-text" className="col-form-label">Adres Tarifi: *</label>
                      <input value={AdresTarifi} onChange={(e)=> setAdresTarifi(e.target.value)}className="form-control" type="text" id="message-text"/>
                      {error && AdresTarifi.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}
                  </div>
                  <div>
                  <label htmlFor="phone">Cep Telefonu:</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">+90</span>
                      </div>
                      <input type="number" value={telefon}     onInput={(e) => {if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10)}}
                          name='phone' onChange={(e)=> settelefon(e.target.value)} className="form-control"  aria-label="Phone" aria-describedby="basic-addon1"/>
                    </div>
                    {error && telefon.length < 10 &&  <span style={{color: "red"}}>{"Telefon numarası 10 sayıdan oluşmalıdır"}</span>}
                  </div>
                  </Modal.Body>
                  <Modal.Footer>
                      <button onClick={()=> {
                          handleClose();
                      }} className="btn me-2b btn-danger">İptal
                      </button>
                      <button onClick={()=> {
                          if( telefon.length < 10 || AdresTarifi.length < 1 || semt.length <1 ||  ilce.length < 1 ||  type.length < 1 ||Address.length < 1 ){
                              setError(true);
                          }
                          else{
                              setError(false);
                              dispatch(addAdress(localStorage.getItem('access-token'),type,ilce,Address,il,semt,AdresTarifi,telefon));
                              handleClose();
                              window.location.reload(false);
                          }
                      }} className="btn btn-outline-secondary">Oluştur
                      </button>
                  </Modal.Footer>
            </Modal> 
            {/* Result Modal */}
            <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Header>
               <Modal.Title>Dikkat!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {message}
            </Modal.Body>
            <Modal.Footer>
               <button onClick={()=> {
                  handleClose2();
                  if(success){
                    navigate('/orders');
                  }
               }}  className="btn btn-outline-secondary">Anladım</button>
            </Modal.Footer>
         </Modal>   
         <Modal
            show={show3}
            onHide={handleClose3}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Body   style={{minHeight:400, display: "flex",justifyContent: "center", alignItems: "center"}}
>
               <Loading/>
            </Modal.Body>
         </Modal>  

      </div>
      <Footer/>
    </>

    )
}
export default Checkout;