import React, { useEffect,useState } from 'react'
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Navtab from '../../components/navtab';
import "./orders.css"
import { fetchOrderHistory, fetchOrders } from '../../statemanagement/actions/orders';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/Loading';

const Order = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {orders,order_history, loading_orders,loading_history} = useSelector(state => state.OrdersReducer);
    function formatDate(date, locales, options) {
        return new Date(date).toLocaleString(locales, options);
    }


    const [address,setAddress] = useState("");
    const [cart,setCart] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    
    useEffect(() => {
        if(localStorage.hasOwnProperty("access-token") === false ){
            navigate('/home');
          }
        else{
            dispatch(fetchOrders(localStorage.getItem('access-token')));
            dispatch(fetchOrderHistory(localStorage.getItem('access-token')));
        }
    },[]);
    console.log(orders);
    return (
        <div>
            <NavBar/>
            <div className='Address'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                <Row className='m-0 my-5'>
                    <Col className="p-0  mx-0 blyat" sm={4}>
                        <Navtab/>
                    </Col>
                    <Col sm={7}>

                        <div id='menu' className=' menu-col mt-2' >
                        {
                            loading_history || loading_orders ? <Loading/>
                            :
                            <>
                            <div className='ps-3 pt-3 row'>
                                <h1 className='col-xl-7 col-5 heading'>
                                    Siparişler
                                </h1>
                                {orders.orders.length === 0 ? <></> :
                                
                                <button onClick={()=>{
                                            setShow3(true);
                                            setAddress(orders.orders[0].Address);
                                }} 
                                className='col-xl-3 col-6 btn  btn-outline-success'>
                                    Hazırlanan siparişi göster
                                </button>
                            }
                                
                            </div>
                            <div className='ps-3 pe-3 pt-3 row'>
                            <div className="table-responsive-xl ">
                                <table className="table">
                                <thead className='table-dark  container-fluid'>
                                    <tr>
                                    <th scope="col">Sipariş No</th>
                                    <th scope="col">Miktar</th>
                                    <th scope="col">Addres</th>
                                    <th scope="col">Ürünler</th>
                                    <th scope="col">Tarih</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    loading_orders ? <></>
                                    :
                            
                                    order_history.orders.map((e)=>
                                    <tr>
                                        <th>{e.id}</th>
                                        <td>{e.total}{"₺"}</td>
                                        <td><a  onClick={()=>{
                                            setShow(true);
                                            setAddress(e.Address);
                                        }}  className='btn p-1 m-1 btn-outline-dark address-link'>Adresi göster</a></td>
                                        <td><a onClick={()=>{
                                            setShow2(true);
                                            setCart(e.order_items);
                                        }} className='btn p-1 m-1 btn-outline-dark address-link'>Ürünleri göster</a></td>
                                        <td>{formatDate(e.date_ordered,"en-US", { dateStyle: "short",timeStyle: "medium"})}</td>
                                    </tr>
                                    )
                                    }
                                </tbody>
                                </table>
                            </div>
                            </div>
                            </>
                        }
                            
                        </div>
                    </Col>
                </Row>    
            </Tab.Container>
            </div>
            <Footer/>
            {/* Current Address Modal */}
            <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
                <Modal.Title>Siparişin Adresi</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <>            
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='col-12'>
                            {address.type}
                        </h4>
                        <p className='text-secondary col-12'>
                            {address.Address} <br/>
                            {address.semt}{", "} {address.ilce} {", "} {address.il} <br/>
                            {address.AdresTarifi}
                        </p>
                    </div>
                </div>
                </>
            </Modal.Body>

            <Modal.Footer>
                    <button onClick={handleClose} className="btn me-2b btn-danger">Kapat
                    </button>
                </Modal.Footer>
            </Modal>
            {/* Current Cart Modal */}
            <Modal
            scrollable
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
                <Modal.Title>Siparişin Ürünleri</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {cart.map((e)=>
                <>            
                <div className='row pt-3 border-bottom'>
                    <div className='col-12'>
                        <h4 className='title col-12'>
                            {e.product_name} {' ( '} {e.product_type} {' ) '} {e.quantity}{" Adet"}
                        </h4>
                        <p className='text-secondary col-12'>
                            {e.product_desc}
                        </p>
                        <h4  className="col-7 title"> {e.product_price}{" ₺ "}  </h4>
                    </div>
                </div>
                </>
            )}
            </Modal.Body>

            <Modal.Footer>
                    <button onClick={handleClose2} className="btn me-2b btn-danger">Kapat
                    </button>
                </Modal.Footer>
            </Modal>
            {/* Current Order Modal */}
            <Modal scrollable
            show={show3}
            onHide={handleClose3}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
                <Modal.Title>Siparişin Adresi</Modal.Title>
            </Modal.Header>
            <Modal.Body as='div' className='addresmodal pb-0'>
                <>            
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='col-12'>
                            {address.type}
                        </h4>
                        <p className='text-secondary col-12 mb-0'>
                            {address.Address} <br/>
                            {address.semt}{", "} {address.ilce} {", "} {address.il} <br/>
                            {address.AdresTarifi}
                        </p>
                    </div>
                </div>
                </>
            </Modal.Body>
            
            <Modal.Header>
                <Modal.Title>Siparişin Ürünleri</Modal.Title>
            </Modal.Header>
            <Modal.Body >
            {!loading_orders && orders.orders.length>0 ?
            (orders.orders[0].order_items).map((e)=>
                <>            
                <div className='row pt-3 border-bottom'>
                    <div className='col-12'>
                        <h4 className='title col-12'>
                            {e.product_name} {' ( '} {e.product_type} {' ) '} {e.quantity}{" Adet"}
                        </h4>
                        <p className='text-secondary col-12'>
                            {e.product_desc}
                        </p>
                        <h4  className="col-7 title"> {e.product_price}{" ₺ "}  </h4>
                    </div>
                </div>
                </>
            ):<></>
            }
            
            </Modal.Body>

            <Modal.Footer>
                    <button onClick={handleClose3} className="btn me-2b btn-danger">Kapat
                    </button>
            </Modal.Footer>

            </Modal>
        </div>
    )
}

export default Order;