import { Home } from './pages/Home/Home';
import {BrowserRouter as Router,Routes, Route} from "react-router-dom";
import Signup from './pages/Signup/Signup';
import Login from './pages/Login/Login';
import Forgot from './pages/Forgot/Forgot';
import Menu from './pages/Menu/Menu';
import Contact from './pages/contact/contact';
import Address from './pages/address/address';
import Order from './pages/orders/order';
import Points from './pages/points/points';
import Account from './pages/account/account';
import { Verify_failed } from './pages/verify/verify_fail';
import Verify_Success from './pages/verify/verify_success';
import Checkout from './pages/checkout/checkout';
import ForgotForm from './pages/Forgot/ForgotForm';
import { Forgot_failed } from './pages/Forgot/ForgotFailed';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='home' element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="Menu" element={<Menu />} />
        <Route path="contact" element={<Contact />} />
        <Route path="address" element={<Address/>} />
        <Route path="account" element={<Account/>} />
        <Route path="pastpoints" element={<Points/>} />
        <Route path="orders" element={<Order/>} />
        <Route path='verify-fail' element={<Verify_failed/>}/>
        <Route path='verify-success' element={<Verify_Success/>}/>
        <Route path='reset-fail' element={<Forgot_failed/>}/>
        <Route path="*" element={<Home />} />
        <Route path='checkout' element={<Checkout />}/>
        <Route path='reset-pass/:uidb64/:token' element={<ForgotForm/>}/>
    </Routes>
    </Router>

  );
}

export default App;
