import React from 'react'
import "./Footer.css"
export const Footer = () => {
  return (
        <div>
            <div className="footer-basic">
                    <div className="social">
                        <a href="https://www.facebook.com/Americanvibee/"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/usa_vibe/?hl=en"><i className="fab fa-instagram"></i></a>
                    </div>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="home">ANASAYFA</a></li>
                        {/* <li className="list-inline-item"><a href="#">HAKKIMIZDA</a></li> */}
                        <li className="list-inline-item"><a href="menu">MENU</a></li>
                        <li className="list-inline-item"><a href="contact">İLETİŞİM</a></li>
                    </ul>
                    <p className="copyright">Sushi Vibe © 2023</p>
            </div>
        </div>
  )
}
