import {  ADD_TO_CART,REMOVE_FROM_CART,DECREASE_QNTY,EMPTY_CART, GEL_AL, USE_POINTS,GET_CART_IDS,GET_TOTAL} from "../actions/cart";

const initialState = loadFromLocalStorage() === undefined ? {
    cart: [],
    cartcount: 0,
    total:0,
    gel_al:false,
    use_points:false,
    cart_ids:[],
} : loadFromLocalStorage();

function saveToLocalStorage(cart) {
    try {
        const serializedCart = JSON.stringify(cart);
        window.localStorage.setItem('cart', serializedCart);
    } catch(e) {
        console.log(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serializedCart = window.localStorage.getItem('cart');
        if(serializedCart === null) return undefined;
        return JSON.parse(serializedCart);
    } catch(e) {
        console.log(e);
        return undefined;
    }
}


function CartReducer(state=initialState,action){
   switch(action.type){
        case GEL_AL:
            state.gel_al = action.payload;
            break;
        case USE_POINTS:
            state.use_points = action.payload;
            break;
        case ADD_TO_CART:
            var id = action.payload.id;
            var object = {};
            var flag = false;
            state.cart.forEach(element => {
                if(id === element.id){
                    element.quantity =element.quantity +1;
                    state.total = state.total + action.payload.price;
                    state.cartcount++;
                    flag = true;
                }
            });
            if(!flag){
                object = {...action.payload,"quantity":1};
                state.cart.push(object);    
                state.total = state.total + action.payload.price;
                state.cartcount++;
            }
            break;
        case DECREASE_QNTY:
            var id = action.payload;
            state.cart.forEach(element => {
                const index = state.cart.indexOf(element);
                if(id === element.id){
                    element.quantity = element.quantity -1;
                    state.total = state.total - element.price;
                    state.cartcount--;
                    if(element.quantity === 0){
                        state.cart.splice(index,1);
                    }
                }
            });
            break;
        case REMOVE_FROM_CART:
            var id = action.payload;
            state.cart.forEach(element => {
                const index = state.cart.indexOf(element);
                if(id === element.id){
                    state.total = state.total - (element.price * element.quantity);
                    state.cartcount = state.cartcount - element.quantity;
                    element.quantity = 0;
                    if(element.quantity === 0){
                        state.cart.splice(index,1);
                    } 
                }
            });
            break;
        case EMPTY_CART:
            state.cart = [];
            state.cartcount = 0;
            state.total = 0;
            break;
        case GET_CART_IDS:
            state.cart.forEach(element => {
                var id = element.id
                state.cart_ids.push(id);
            });
            break;
        case GET_TOTAL:
            var sum = 0;
            state.cart.forEach(element => {
                var cost = element.price * element.quantity;
                sum = sum + cost;
            });
            state.total = sum;
            break;
        default:
            break;
   }
   saveToLocalStorage(state);
   return {
    ...state,
    }  

}
export default CartReducer;