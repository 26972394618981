import Nav from 'react-bootstrap/Nav';
import React from 'react';

const Navtab = () => {
  return (
          <Nav variant="pills" className="blyat2 ps-3 flex-column">
            <Nav.Item>
              <Nav.Link href='account' eventKey="first">Hesabim</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link   href='orders' eventKey="second">Geçmiş Siparişlerim</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='address' eventKey="third">Adreslerim</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link  href='pastpoints' eventKey="fourth">Puan Geçmişi</Nav.Link>
            </Nav.Item>
          </Nav>
  )
}

export default Navtab;