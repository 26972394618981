import { domain } from "../../domain";

export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';

export const fetchProducts = () => {
  return async dispatch =>{
    //Start Loading
    dispatch(
      {
        type : FETCH_ITEMS_REQUEST,
      });

    try{
      var requestOptions = {
      method: 'GET',
      redirect: 'follow'
      };
    
      fetch(`${domain}/api/product-cust/`, requestOptions)
      .then(response => response.text())
        .then(result => {
          result = JSON.parse(result);
          dispatch(
            {
              type : FETCH_ITEMS_SUCCESS,
              payload:result,
            }
          );
        })
        .catch(error =>{
          dispatch(
            {
              type : FETCH_ITEMS_FAILURE,
              payload:error,
            }
          );
        })
    }
    catch{

    }
  }
}