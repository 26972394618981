import { domain } from "../../domain";
import { signout } from "./account";

export const ADD_ADDRESS= 'ADD_ADDRESS';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const GET_ALL_ADDRESS ="GET_ALL_ADDRESS";
export const SET_LOADING ="SET_LOADING";

export const addAdress = (token,type,ilce,Address,il,semt,AdresTarifi,telefon) =>{
  try{
       return async dispatch =>{
        dispatch(
          {
            type : ADD_ADDRESS,
            payload: false
          }
        );
         let myHeaders = new Headers();
         myHeaders.append("Authorization", `Bearer ${token}`);
         var formdata = new FormData();
         formdata.append("type",type );
         formdata.append("ilce",ilce);
         formdata.append("Address",Address );
         formdata.append("il",il );
         formdata.append("semt", semt);
         formdata.append("AdresTarifi",AdresTarifi );
         formdata.append("telefon", telefon);
         
         var requestOptions = {
           method: 'POST',
           headers: myHeaders,
           body: formdata,
           redirect: 'follow'
         };         
       await fetch(`${domain}/api/account/adresses`, requestOptions)
       .then(response => response.json())
       .then(result => {
        if(result.code === "token_not_valid"){
          localStorage.clear();
          window.location.reload();

        }
        else{
         dispatch(
             {
               type : ADD_ADDRESS,
               payload: true
             }
           )
            }
       })
       .catch(error => console.log('error', error));
   }}
   catch{}
}
export const removeAddress = (token,id) =>{
   try{
        return async dispatch =>{
          let myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);
          var formdata = new FormData();
          formdata.append("id", id);
  
          var requestOptions = {
              method: 'DELETE',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow'
          };
        await fetch(`${domain}/api/account/adresses`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.code === "token_not_valid"){
            localStorage.clear();
            window.location.reload();

          }
          else{
            dispatch(
              {
                type : REMOVE_ADDRESS
              }
            )
          }
        })
        .catch(error => console.log('error', error));
    }}
    catch{
  
    }
}

export const getAddress = (token) => {
    try{
        return async dispatch =>{
          let myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);
          let requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
          };
  
        await fetch(`${domain}/api/account/adresses`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.code === "token_not_valid"){
            localStorage.clear();
            window.location.reload();

          }
          else{
            dispatch(
              {
                type : GET_ALL_ADDRESS,
                payload: result.adresses,
              }
            )
            dispatch(
                {
                  type : SET_LOADING,
                  payload: false,
                }
              )
          }

        })
        .catch(error => {
          console.log("error",error);
        });
    }}
    catch{
  
    }
}