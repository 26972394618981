import { domain } from "../../domain";

export const GET_INFO= 'GET_INFO';
export const UPDATE_INFO = 'UPDATE_INFO';
export const RESET_PASS = 'RESET_PASSWORD';
export const RESET_EMAIL = 'RESET_EMAIL';
export const LOGOUT = 'LOG_OUT';

export const getUserinfo = (token) => {
    try{
        return async dispatch =>{
          let myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);
          let requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
          };
  
        await fetch(`${domain}/api/account/userinfo`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.code === "token_not_valid"){
            localStorage.clear();
            window.location.reload();
          }
          else{
            dispatch(
                {
                  type : GET_INFO,
                  payload: result,
                }
              )
            }
          })
        
        .catch(error => console.log("a7a",error));
    }}
    catch{
  
    }
}

export const updateInfo = (token,phone,name) => {
  try{
      return async dispatch =>{
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var formdata = new FormData();
        formdata.append("phone", phone);
        formdata.append("fullname", name);
        
        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
      
      await fetch(`${domain}/api/account/userinfo`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.code === "token_not_valid"){
          localStorage.clear();
          window.location.reload();

        }
        else{
        dispatch(
            {
              type : UPDATE_INFO,
            }
          )
        }
      })
      .catch(error => console.log('error', error));
  }}
  catch{

  }
}

export const resetPass = (token,password) => {
  try{
      return async dispatch =>{
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var formdata = new FormData();
        formdata.append("password", password);
        
        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
      await fetch(`${domain}/api/account/userinfo/resetpass`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.code === "token_not_valid"){
          localStorage.clear();
          window.location.reload();

        }
        else{
        dispatch(
            {
              type : RESET_PASS,
            }
          )
        }
      })
      .catch(error => console.log('error', error));
  }}
  catch{

  }
}

export const signout = (accesstoken,refreshtoken) =>{
  var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accesstoken}`);

    var formdata = new FormData();
    formdata.append("refresh", refreshtoken);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${domain}/api/auth/logout`, requestOptions)
      .then(response =>{
        if(response.status === 204){
            localStorage.clear();
        }
        if(response.status === 401){
          localStorage.clear();
        }
          localStorage.clear();
          window.location.reload();
       }
      )
      .catch(error => console.log('error', error));
}