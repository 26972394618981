import React, { useEffect } from 'react'
import { useState } from 'react';
import { NavBar } from '../../components/NavBar';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import {getAddress} from '../../statemanagement/actions/adress';
import { domain } from '../../domain';

const Login = () => {
   let navigate = useNavigate();
   const dispatch = useDispatch();

   const login_request = () =>{
      var formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);

      var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
      };

      fetch(`${domain}/api/auth/login/`, requestOptions)
      .then(response => response.text())
      .then(result => {
         const obj = JSON.parse(result);
         if('detail' in obj){
            const temp = obj.detail;
            if(temp.includes("Email")){
               setMessage("E-posta kimliği doğrulanmadı!");
            }
            else{
               setMessage("Geçersiz kimlik bilgileri, tekrar deneyin!");
            }
            handleShow();
         }
         else{
            localStorage.setItem('access-token',obj.tokens.access);
            localStorage.setItem('refresh-token',obj.tokens.refresh);
            localStorage.setItem('email',obj.email);
            dispatch(getAddress(obj.tokens.access));
            handleShow2();
         }
      })
      .catch(error => {
         setMessage("Beklenmeyen bir hata oluştu Sonuç bulunamadı");
         handleShow();
      });
   }
   const [password,setPassword] = useState("");
   const [email,setEmail] = useState("");
   const [message,setMessage] = useState("");
   const [show, setShow] = useState(false);
   const [show2, setShow2] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const handleShow2 = () => setShow2(true);
   const handleA7a = ()=>{
      setShow(false);
      navigate('/menu');
   };
   useEffect(() => {
      if(localStorage.getItem('access-token') !== null){
         navigate('/home');
      }
   });
   
   return (
         <>
         <NavBar/>
         <div className="wrapper">
            <div className='form-wrapper'>
               <h2>Kullanıcı Girişi </h2>
                  <div className='email'>
                     <label htmlFor="email">E-posta:</label>
                     <input type='email'  value={email} name='email' onChange={(e)=> setEmail(e.target.value)}/>
                  </div>
                  <div className='password'>
                     <label htmlFor="password">Şifre:</label>
                     <input type='password' value={password} name='password' onChange={(e)=> setPassword(e.target.value)}/>
                  </div>     
                  <div className='d-flex ' >      
                   <a className='flex-grow-1 a-link ' href='/forgot'>Şifremi Unuttum!</a>
                   </div>
                  <div className='submit'>
                     <button onClick={(e) => {login_request();}} className='submit-btn'>Login</button>
                  </div>
            </div>
         </div>
         <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Header>
               <Modal.Title>Dikkat!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {message}
            </Modal.Body>
            <Modal.Footer>
               <button onClick={()=> {
                  handleClose();
               }} className="btn btn-outline-secondary">Anladım</button>
            </Modal.Footer>
         </Modal>  
         <Modal
            show={show2}
            onHide={handleA7a}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Header>
               <Modal.Title>Dikkat!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Başarıyla Giriş Yaptınız
            </Modal.Body>
            <Modal.Footer>
               <button onClick={()=> {
                  handleA7a();
               }}  className='submit-btn'>Hemen Sipariş ver!</button>
            </Modal.Footer>
         </Modal>  
      </>
   )
}
export default Login;