import React, { useEffect } from 'react'
import { Footer } from '../../components/Footer';
import { NavBar } from '../../components/NavBar';
import Tab from 'react-bootstrap/Tab';
import Navtab from '../../components/navtab';
import "./address.css"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import {getAddress,removeAddress,addAdress} from '../../statemanagement/actions/adress';
import semtler from "../../assets/ilce-semt.json";
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/Loading';

const Address = () => {
    
    let navigate = useNavigate();
    const {addresses,loading} = useSelector(state => state.AdressReducer);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [type,setType] = useState("");
    const [Address,setAddress] = useState("");
    const [il,setil] = useState("İzmir");
    const [ilce,setilce] = useState("");
    const [semt,setsemt] = useState("");
    const [AdresTarifi,setAdresTarifi] = useState("");
    const [telefon,settelefon] = useState("");
    const [error,setError] = useState(false);



    const iller = ["Bayraklı","Bornova","Buca","Karbağlar","Karşıyaka","Konak"];
    //Pull the addresses Before loading the page
    useEffect(() => {
        
        if(localStorage.getItem('access-token') === null){
            navigate('/home');
        }
        else{
            dispatch(getAddress(localStorage.getItem('access-token')));
        }
    },[]);

    return (
        <div>
            <NavBar/>
            <div className='Address'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="third">
                <div className='row m-0 my-5'>
                    <div className=" blyat col-sm-12 col-md-4">
                        <Navtab/>
                    </div>
                    <div className='mobile-col col-sm-12 col-md-8 '>
                        <div id='menu' className='add-col' >
                            <div className='ps-3 pt-3 row'>
                                <h1 className=' col-md-8 col-6 heading'>
                                    Adreslerim
                                </h1>
                                <button onClick={handleShow} className='btn btn-outline-dark w-10 col-lg-2 col-md-3 col-5'>
                                    Yeni Adres
                                </button>
                            </div>
                            <>
                            {
                                loading ?
                                <Loading/>
                                :<>
                                    {addresses.map((e)=>
                                        <>            
                                        <div className='row ps-3 pt-3'>
                                            <div className='col-10'>
                                                <h4 className='col-12'>
                                                    {e.type}
                                                </h4>
                                                <p className='text-secondary col-12'>
                                                    {e.Address} <br/>
                                                    {e.semt}{", "} {e.ilce} {", "} {e.il} <br/>
                                                    {e.AdresTarifi}
                                                </p>
                                            </div>
                                            <div className='col-2'>
                                                <a onClick={()=>{
                                                        dispatch(removeAddress(localStorage.getItem('access-token'),e.id))
                                                        window.location.reload(false);
                                                }}>
                                                    <i className="fas fa-minus-circle fa-sm  "></i>
                                                </a>
                                            </div>
                                        </div>
                                        <hr/>
                                        </>
                                    )
                                    }
                                </>
                            }
                            </>   
                        </div>
                    </div>
                </div>    
            </Tab.Container>
            </div>
            <Footer/>
            <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Yeni Adres</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {/* Type */}
                <div >
                    <label for="disabledSelect" className="form-label">Adres Tipi: *</label>
                    <select id="disabledSelect" onChange={(e)=> setType(e.target.value)} className="form-select">
                        <option selected disabled hidden>Lütfen seçiniz...</option>
                        <option value={"Ev"}>Ev</option>
                        <option value={"İş"} >İş</option>
                        <option value={"Kampüs"} >Kampüs</option>
                    </select>
                    {error && type.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}

                </div>
                <div>
                    <label for="message-text" className="col-form-label">Adres: *</label>
                    <input value={Address}  onChange={(e)=> setAddress(e.target.value)}className="form-control" type="text" id="message-text"/>
                    {error && Address.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}
                </div>
                <div>
                    <label for="disabledSelect" className="form-label">İl: *</label>
                    <select id="disabledSelect" value={il} disabled  placeholder='İzmir' onChange={(e)=> setil(e.target.value)} className="form-select">
                        <option selected disabled hidden value={'İzmir'}>İzmir</option>
                    </select>
                </div>
                <div>
                    <label for="disabledSelect" className="form-label">İlçe: *</label>
                    <select id="disabledSelect" onChange={(e)=> setilce(e.target.value)} className="form-select">  
                        <option selected disabled hidden>Lütfen seçiniz...</option>
                        {iller.map((option, index) => {
                            return <option key={index} >
                                {option}
                            </option>
                        })}
                    </select>
                    {error && ilce.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}

                </div>
                <div >
                    <label for="disabledSelect" className="form-label">Semt: *</label>
                    <select id="disabledSelect" onChange={(e)=> setsemt(e.target.value)} className="form-select">  
                        <option selected disabled hidden>Lütfen seçiniz...</option>
                        {semtler[ilce].map((option, index) => {
                            return <option key={index} >
                                {option}
                            </option>
                        })}
                    </select>
                    {error && semt.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}
                </div>
                <div>
                    <label for="message-text" className="col-form-label">Adres Tarifi: *</label>
                    <input value={AdresTarifi} onChange={(e)=> setAdresTarifi(e.target.value)}className="form-control" type="text" id="message-text"/>
                    {error && AdresTarifi.length < 1 &&  <span style={{color: "red"}}>{"Bu alan boş bırakılmaz!"}</span>}
                </div>
                <div>
                <label htmlFor="phone">Cep Telefonu:</label>
                  <div className="input-group">
                     <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">+90</span>
                     </div>
                     <input type="number" value={telefon}     onInput={(e) => {if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10)}}
                         name='phone' onChange={(e)=> settelefon(e.target.value)} className="form-control"  aria-label="Phone" aria-describedby="basic-addon1"/>
                  </div>
                  {error && telefon.length < 10 &&  <span style={{color: "red"}}>{"Telefon numarası 10 sayıdan oluşmalıdır"}</span>}
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={()=> {
                        handleClose();
                    }} className="btn me-2b btn-danger">İptal
                    </button>
                    <button onClick={()=> {
                        if( telefon.length < 10 || AdresTarifi.length < 1 || semt.length <1 ||  ilce.length < 1 ||  type.length < 1 ||Address.length < 1 ){
                            setError(true);
                        }
                        else{
                            setError(false);
                            dispatch(addAdress(localStorage.getItem('access-token'),type,ilce,Address,il,semt,AdresTarifi,telefon));
                            handleClose();
                            window.location.reload(false);
                        }
                    }} className="btn btn-outline-secondary">Oluştur
                    </button>
                </Modal.Footer>
            </Modal>  
        </div>
    )
}

export default Address;