import React from 'react'
import { useState,useEffect } from 'react';
import "../loginandsignup.css";
import { NavBar } from '../../components/NavBar';
import { domain } from '../../domain';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const Forgot = () => {

   const [email,setEmail] = useState("");
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [show, setShow] = useState(false);
   const [message,setMessage] = useState("");
   let navigate = useNavigate();
   useEffect(() => {
      if(localStorage.getItem('access-token') !== null){
         navigate('/home');
      }
   },[]);

   const handlesubmission = () =>{
         var formdata = new FormData();
         formdata.append("email", email);
         var requestOptions = {
         method: 'POST',
         body: formdata,
         redirect: 'follow'
         };

         fetch(`${domain}/api/auth/request-reset-email/`, requestOptions)
         .then(response => response.text())
         .then(result => {
            var res = JSON.parse(result)
            if (res.success !=undefined){
               setMessage("E-postanıza şifrenizi sıfırlamak için size bir bağlantı gönderdik")
            }
            else{
               setMessage("Beklenmeyen bir hata oluştu");
            }
            handleShow();
         })
         .catch(error => console.log('error', error));
   }
   
   return (
         <>
         <NavBar/>
         <div className='wrapper'>
            <div className='form-wrapper'>
               <h2>Şifremi Unuttum</h2>
               <div  noValidate >
                  <div className='email'>
                     <label htmlFor="email">E-posta:</label>
                     <input type='email'  value={email} name='email' onChange={(e)=> setEmail(e.target.value)}/>
                  </div>        
                  <div className='submit'>
                     <button onClick={()=> handlesubmission()} className='submit-btn'>Şifre Sıfırla</button>
                  </div>
            </div>
            </div>
         </div>
         <Modal
         show={show}
         onHide={handleClose}
         backdrop="static"
         keyboard={false}
         >
         <Modal.Header>
            <Modal.Title>Dikkat!</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            {message}
         </Modal.Body>
         <Modal.Footer>
            <button onClick={()=>handleClose()} className="check-btn">Anladım</button>
         </Modal.Footer>
         </Modal>   
         </>
   )
}
export default Forgot;