import { ADD_ADDRESS,REMOVE_ADDRESS,GET_ALL_ADDRESS,SET_LOADING } from "../actions/adress";
const initialState ={
    addresses: [],
    loading: true,
    added : false,
}

function AdressReducer(state=initialState,action){
   switch(action.type){
        case GET_ALL_ADDRESS:
            return {
                ...state,
                addresses:action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading:action.payload
            };
        case REMOVE_ADDRESS:
            return{
                ...state,
                loading:true,
        };
        case ADD_ADDRESS:
            return{
                ...state,
                loading:action.payload,
                added: action.payload
        };
        default:
            return state;
   }
}
export default AdressReducer;